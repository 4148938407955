import Button from "../form/button";
import InputField from "../form/input";
import React, { useState } from "react";
import { RootState } from "../../app/store";
import logo from "./../../assets/logo/logo.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setEmail,
  setContactBy,
  setFirstName,
  setLastName,
  setUser,
} from "../../features/users/usersSlice";
import { useMutation } from "@apollo/client";
import { classNames } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { REGISTER_USER_REQUEST } from "../../api/users/mutations";
import { dismissSellerRequestModal } from "../../features/utils/utilsSlice";

interface Props {}

const SellerRequest = (props: Props) => {
  const [state, setState] = useState({
    isWaiting: false,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.users.user);
  // const access_token = useAppSelector(
  //   (state: RootState) => state.users.access_token
  // );
  const location = useAppSelector((state: RootState) => state.utils.location);

  const [registerUserRequest] = useMutation(REGISTER_USER_REQUEST, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        dispatch(setUser(data.updateUser));
        navigate("/shops/become-seller");
        dispatch(dismissSellerRequestModal());
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "email":
        dispatch(setEmail(e.target.value));
        break;
      case "lastname":
        dispatch(setLastName(e.target.value));
        break;
      case "firstname":
        dispatch(setFirstName(e.target.value));
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e: any) => {
    let data;
    if (user.contactBy === "email" && user.isEmailValid) {
      data = {
        email: user?.email,
        phone: user?.phone,
        firstname: user?.firstname,
        lastname: user?.lastname,
      };
    } else {
      data = {
        phone: user?.phone,
        firstname: user?.firstname,
        lastname: user?.lastname,
      };
    }
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    registerUserRequest({
      variables: data,
    });
  };

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-md px-3 my-7">
        <div className="flex justify-center items-center my-2">
          <img src={logo} alt="" className="h-20 object-scale-down" />
        </div>
        <div className="flex-col justify-center items-center">
          <div>
            <span>Comment voulez vous être contacté ?</span>
          </div>
          <div
            className={
              user.contactBy === "call"
                ? classNames(
                    "flex items-center my-1 cursor-pointer w-full p-2 rounded-md bg-gray-200"
                  )
                : classNames(
                    "flex items-center my-1 cursor-pointer w-full p-2 rounded-md"
                  )
            }
            onClick={() => {
              dispatch(setContactBy("call"));
            }}
          >
            <input
              type="radio"
              value="male"
              name="gender"
              onChange={() => dispatch(setContactBy("call"))}
              checked={user.contactBy === "call"}
            />{" "}
            <span className="px-2">Appel </span>
          </div>
          <div
            className={
              user.contactBy === "email"
                ? classNames(
                    "flex items-center my-1 cursor-pointer w-full p-2 rounded-md bg-gray-200"
                  )
                : classNames(
                    "flex items-center my-1 cursor-pointer w-full p-2 rounded-md"
                  )
            }
            onClick={() => {
              dispatch(dispatch(setContactBy("email")));
            }}
          >
            <input
              type="radio"
              name="gender"
              value="female"
              onChange={() => dispatch(setContactBy("email"))}
              checked={user.contactBy === "email"}
            />
            <span className="px-2">E-mail</span>
          </div>
          <InputField
            type="text"
            id="lastname"
            autoFocus={true}
            isRequired={true}
            labelName="lastname"
            value={user.lastname}
            isValid={user.isLastNameValid}
            placeholder="Entrez votre nom ici"
            handleInputChange={handleInputChange}
          />
          <InputField
            type="text"
            id="firstname"
            isRequired={true}
            labelName="firstname"
            value={user.firstname}
            isValid={user.isFirstNameValid}
            placeholder="Entrez votre prénom ici"
            handleInputChange={handleInputChange}
          />
          {user.contactBy === "email" && (
            <InputField
              id="email"
              type="email"
              value={user.email}
              labelName="email"
              handleInputChange={handleInputChange}
              isValid={user.isEmailValid ? true : false}
              placeholder="Entrez votre address e-mail ici"
            />
          )}
          <InputField
            id="phone"
            type="tel"
            isDisabled={true}
            labelName="phone"
            value={user.phone}
            icon={location?.country_flag}
            calling_code={location?.calling_code}
            placeholder="Entrez votre numéro ici"
            handleInputChange={handleInputChange}
            info={location?.country_code2.toLocaleUpperCase()}
          />
          <Button
            title="Valider"
            className="px-3"
            hidden={user.isRegisteredOnU54}
            disabled={
              state.isWaiting
                ? true
                : user.contactBy === "email"
                ? user.isEmailValid
                  ? false
                  : true
                : false || !user.isFirstNameValid || !user.isLastNameValid
            }
            handleOnClick={handleSubmit}
            isWaiting={state.isWaiting}
          />
        </div>
      </div>
    </div>
  );
};

export default SellerRequest;
