import Button from "../form/button";
import InputField from "../form/input";
import { RootState } from "../../app/store";
import logo from "./../../assets/logo/logo.png";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setIsDeliveryFree,
  setProductCategories,
  setProductDescription,
  setProductName,
  setProductPrice,
  setProductQuantity,
  startWaiting,
  stopWaiting,
} from "../../features/products/productsSlice";
import { updateProduct as updateProductAction } from "../../features/shops/shopsSlice";
import {
  ChevronLeft,
  ChevronRight,
  ExpandMoreSharp,
} from "@mui/icons-material";
import toast from "react-hot-toast";
import { removeSpace } from "../../utils/format";
import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT } from "../../api/product/mutations";
import Label from "./label";

interface Props {
  handleDismiss: any;
}

let count = 0;
let slideInterval;

const UpdateProduct = (props: Props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    loaded: false,
  });
  const product = useAppSelector((state: RootState) => state.products.product);
  const activeProduct = useAppSelector(
    (state: RootState) => state.products.activeProduct
  );
  const categories = useAppSelector(
    (state: RootState) => state.utils.categories
  );
  const isWaiting = useAppSelector(
    (state: RootState) => state.products.isWaiting
  );

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: async (res) => {
      if (res) {
        dispatch(updateProductAction(res.updateProduct));
        props.handleDismiss();
        dispatch(stopWaiting());
        toast.success("Votre produit a été modifié avec success.");
      } else {
        dispatch(stopWaiting());
      }
    },
    onError: (error) => {
      console.error(error);
      dispatch(stopWaiting());
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      product.name === activeProduct?.name &&
      product.price === activeProduct?.price &&
      product.description === activeProduct?.description &&
      product.isDeliveryFree === activeProduct?.isDeliveryFree &&
      product.quantity === activeProduct?.quantity &&
      [...product.categories?.map((item) => item)].sort().join(",") ===
        activeProduct?.categories
          ?.map((item) => item?._id)
          .sort()
          .join(",")
    ) {
      props.handleDismiss();
    } else {
      dispatch(startWaiting());
      updateProduct({
        variables: {
          updateProductInput: {
            id: activeProduct._id,
            name: product.name,
            price:
              typeof product.price === "number"
                ? product.price
                : parseInt(removeSpace(product.price)),
            quantity:
              typeof product.quantity === "number"
                ? product.quantity
                : parseInt(removeSpace(product.quantity)),
            categories: product.categories,
            description: product.description,
            isDeliveryFree: product.isDeliveryFree,
          },
        },
      });
    }
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "name":
        dispatch(setProductName(e.target.value));
        break;
      case "price":
        dispatch(setProductPrice(e.target.value));
        break;
      case "description":
        dispatch(setProductDescription(e.target.value));
        break;
      case "quantity":
        dispatch(setProductQuantity(e.target.value));
        break;
      default:
        break;
    }
  };

  const handleCheckboxInput = (e) => {
    dispatch(setProductCategories(e.target.value));
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideRef = useRef<HTMLDivElement>(null);

  const handleChevronLeft = () => {
    const size = activeProduct?.images?.length;
    count = (currentIndex + size - 1) % size;
    setCurrentIndex(count);
  };

  const handleChevronRight = useCallback(() => {
    count = (count + 1) % activeProduct?.images?.length;
    setCurrentIndex(count);
  }, [activeProduct?.images?.length]);

  const startSlider = useCallback(() => {
    slideInterval = setInterval(() => {
      handleChevronRight();
    }, 100000);
  }, [handleChevronRight]);

  useEffect(() => {
    startSlider();
    if (slideRef.current) {
      slideRef.current.addEventListener("mouseenter", pauseSlider);
      slideRef.current.addEventListener("mouseleave", startSlider);
    }

    return () => {
      clearInterval(slideInterval);
    };
  }, [startSlider]);

  const pauseSlider = () => {
    clearInterval(slideInterval);
  };

  return (
    <div className="flex flex-col justify-center items-center p-2">
      <div className="flex justify-center items-center">
        <img src={logo} alt="" className="h-20 object-scale-down max-w-max" />
      </div>
      <div className="flex flex-col md:flex-row justify-evenly items-center w-full h-full border">
        <div
          ref={slideRef}
          className="relative flex flex-col justify-center items-center h-full w-full border-0 md:border p-2 m-1 md:mr-0 md:rounded-l-md"
        >
          <div className="flex flex-col justify-center items-center h-80 w-full">
            <img
              onLoad={() => {
                setState((prevState) => ({
                  ...prevState,
                  loaded: true,
                }));
              }}
              src={activeProduct?.images[currentIndex]?.lg}
              alt={activeProduct?.description}
              loading="lazy"
              className={
                state.loaded
                  ? "object-scale-down w-full h-full rounded-none"
                  : "rounded-none bg-gray-100 "
              }
            />
          </div>
          {activeProduct?.images?.length > 1 && (
            <div className="absolute w-full top-1/2 transform -translate-y-1/2 px-3 flex justify-between items-center">
              <ChevronLeft
                onClick={() => handleChevronLeft()}
                className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
              />
              <ChevronRight
                onClick={() => handleChevronRight()}
                className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
              />
            </div>
          )}
          <div className="absolute bottom-0 w-full px-1 flex justify-center items-center">
            {activeProduct?.images?.length > 1 && (
              <div className="flex justify-center items-center">
                {activeProduct?.images?.map((item, i) => (
                  <div
                    key={i}
                    className={
                      currentIndex === i
                        ? "bg-black border-white border h-1.5 w-1.5 rounded-full ml-1"
                        : "bg-slate-400 border-white border h-1 w-1.5 rounded-sm ml-1"
                    }
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-full w-full border-0 md:border border-l-0 p-2 m-1 ml-0 rounded-r-md">
          <div className="flex flex-col h-full md:h-80 w-full overflow-y-scroll scrollbar-thin scrollbar-black-thumb">
            <InputField
              id="name"
              type="text"
              autoFocus={true}
              labelName="name"
              value={product.name}
              isValid={product.isNameValid}
              placeholder="Entrez le nom du produit"
              handleInputChange={handleInputChange}
            />
            <InputField
              id="price"
              type="text"
              labelName="price"
              value={product.price}
              isValid={product.isPriceValid}
              placeholder="Entrez le prix du produit"
              handleInputChange={handleInputChange}
            />
            <div
              style={{
                borderColor: product.isDescriptionValid
                  ? "rgba(243, 244, 246, var(--tw-border-opacity))"
                  : "red",
              }}
              className="flex justify-between items-center w-full border-2 border-gray-100 rounded-md p-2 mb-2"
            >
              <textarea
                id="description"
                name="description"
                maxLength={500}
                value={product.description}
                onChange={handleInputChange}
                placeholder="Entrez la description du produit"
                className={`border-none w-full resize-none outline-none text-sm px-2 py-1 rounded-md ml-1 overflow-y-scroll scrollbar-thin scrollbar-black-thumb`}
              />
            </div>
            <InputField
              type="text"
              id="quantity"
              labelName="quantity"
              value={product?.quantity}
              isValid={product?.isQuantityValid}
              handleInputChange={handleInputChange}
              placeholder="Entrez la quantité disponible en stock"
            />
            <div className="flex items-start h-28">
              <div className="flex relative justify-start w-full items-center border-2 border-gray-100 rounded-md p-2 mb-2 group">
                <div
                  className={
                    product?.categories?.length === 0
                      ? "flex justify-between items-center w-full border-none outline-none text-sm p-1 rounded-md"
                      : "inline-block max-w-fit border-none outline-none text-sm p-1 rounded-md"
                  }
                >
                  {product?.categories?.length === 0 && (
                    <div className="w-full flex justify-between items-center">
                      <span className="text-gray-400">Catégories </span>
                      <ExpandMoreSharp className="text-gray-400" />
                    </div>
                  )}
                  {product?.categories?.map((item, i) => (
                    <div
                      key={i}
                      className="max-w-min inline-block border justify-between items-center m-1 rounded-md p-1"
                    >
                      <span className="capitalize text-xs">
                        {
                          categories?.find((el) => el?.node._id === item)?.node
                            ?.name
                        }
                      </span>
                      <span className=" ml-1 text-red-500 rounded">x</span>
                    </div>
                  ))}
                </div>
                <div className="absolute z-50 right-0 top-11 max-w-max hidden group-hover:flex rounded-md p-2 bg-black flex-col hover:h-32 overflow-y-scroll scrollbar-thin scrollbar-white-thump">
                  {categories?.map((item, i) => (
                    <label
                      className="text-black my-0.5 mx-2 bg-white rounded-md p-2 text-xs flex justify-start items-center"
                      htmlFor={item?.node?.name}
                      key={i}
                    >
                      <input
                        id={item?.node?.name}
                        type="checkbox"
                        value={item?.node?._id}
                        onChange={(e) => handleCheckboxInput(e)}
                        checked={product?.categories?.includes(item?.node?._id)}
                      />
                      <span className="ml-1 capitalize">
                        {item?.node?.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="m-2">
              <Label
                isRequired={true}
                style="text-black font-thin text-sm font-bold"
                labelName="delivery"
                labelText="Offrir la livraison... ?"
              />
              <div
                className={
                  product.isDeliveryFree === true
                    ? "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md bg-gray-200"
                    : "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md"
                }
                onClick={() => {
                  dispatch(setIsDeliveryFree(true));
                }}
              >
                <input
                  type="radio"
                  name="delivery"
                  onChange={() => dispatch(setIsDeliveryFree(true))}
                  checked={product.isDeliveryFree === true}
                />{" "}
                <span className="px-2">Oui</span>
              </div>
              <div
                className={
                  product.isDeliveryFree === false
                    ? "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md bg-gray-200"
                    : "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md"
                }
                onClick={() => {
                  dispatch(setIsDeliveryFree(false));
                }}
              >
                <input
                  type="radio"
                  name="delivery"
                  onChange={() => dispatch(setIsDeliveryFree(false))}
                  checked={product.isDeliveryFree === false}
                />
                <span className="px-2">Non</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start w-full">
        <Button
          title="Modifier"
          handleOnClick={handleSubmit}
          className="px-3 max-w-max"
          disabled={
            !product.isPriceValid ||
            !product.isNameValid ||
            !product.isQuantityValid ||
            !product.isDescriptionValid ||
            product.categories.length === 0 ||
            isWaiting
          }
          isWaiting={isWaiting}
        />
      </div>
    </div>
  );
};

export default UpdateProduct;
