import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { RootState } from "../../app/store";
import Order from "../../components/ux/order";
import { useNavigate } from "react-router-dom";
import { ShoppingCartSharp } from "@mui/icons-material";
import Banner from "../../components/layout/banner";
import { setOrders } from "../../features/orders/ordersSlice";
import { ORDERS_BY_PHONE } from "../../api/orders/queries";
import { ReactComponent as NoOrder } from "./../../assets/img/delivery-bro.svg";
import logo from "./../../assets/logo/logo.png";
import { CategorySharp } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  searchFor,
  setFilterKeys,
} from "../../features/deliveries/deliveriesSlice";

interface Props {}

function Orders(props: Props) {
  const [state, setState] = useState({
    isSearchFilterModalOpen: false,
    searchingFor: "",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const calling_code = useAppSelector(
    (state: RootState) => state.utils.location.calling_code
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const orders = useAppSelector((state: RootState) => state.orders.orders);
  const phone = useAppSelector((state: RootState) => state.users.user.phone);

  const { data } = useQuery(ORDERS_BY_PHONE, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !(phone && calling_code),
    errorPolicy: "all",
    variables: {
      phone,
      calling_code,
    },
  });

  const toggleSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: !prevState.isSearchFilterModalOpen,
    }));
  };

  useEffect(() => {
    if (data) {
      dispatch(setOrders(data?.ordersByPhone?.page?.edges));
    }
  }, [data, dispatch]);

  const handleSearchBar = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchingFor: e.target.value,
    }));

    dispatch(searchFor(e.target.value));
  };

  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  return (
    <>
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "5em auto",
        }}
      >
        {/* Header */}
        {/* Header Hack */}
        <div className="header"></div>
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={() => {
                navigate("/marketplace");
              }}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
              <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
                <CategorySharp
                  className={
                    setFilterKeys?.length > 0
                      ? "text-green-400 hover:cursor-pointer bg-transparent border-0"
                      : "hover:cursor-pointer bg-transparent border-0"
                  }
                  onClick={() => toggleSearchFilterModal()}
                />
              </div>
              <input
                className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba caret-black"
                placeholder={"Que cherchez vous ?"}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
                onChange={(e) => handleSearchBar(e)}
                type="text"
                value={state.searchingFor}
                name="searchingFor"
                ref={inputRef}
                style={{
                  fontFamily: "Leckerli One",
                }}
              />
            </div>
          </div>
        </div>
        {/* Content */}
        {orders.length > 0 ? (
          <div
            className=""
            style={{
              gridColumn: "1 / -1",
            }}
          >
            <div className="flex flex-col my-5 md:flex-row justify-between md:justify-evenly w-full h-full px-2 md:p-7">
              <div className="absolute flex flex-col justify-start items-center w-full md:mx-7">
                {orders?.map((item, i) => (
                  <Order order={item} key={i} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center md:h-1/3 flex-col">
            <NoOrder />
          </div>
        )}
        <div className="hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-300 focus:outline-none">
          <div
            onClick={() => {
              navigate("/marketplace/cart");
            }}
            className="flex group justify-center items-center bg-white absolute w-12 h-12 rounded-full"
          >
            <ShoppingCartSharp />
            <div className="min-w-max border absolute mx-1 px-1 flex right-14 top-3 invisible group-hover:visible rounded-md bg-white ">
              <span className="text-xs font-thin">Retour au panier</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Orders;
