import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import logo from "./../../assets/logo/logo.png";
import { setUser } from "../../features/users/usersSlice";
import { useQuery } from "@apollo/client";
import { ME } from "../../api/users/queries";
import Banner from "../../components/layout/banner";
import { CategorySharp } from "@mui/icons-material";

interface Props {}

const Shops = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("access_token");
  const [state, setState] = useState({
    isSearchFilterModalOpen: false,
    searchingFor: "",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const filterKeys = useAppSelector(
    (state: RootState) => state.products.filterKeys
  );
  const { data } = useQuery(ME, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: token === null ? true : false,
    errorPolicy: "all",
    context: {
      headers: { authorization: `Bearer ${token}` },
    },
  });

  useEffect(() => {
    if (data) {
      dispatch(setUser(data?.me));
    }
  }, [data, dispatch]);

  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const toggleSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: !prevState.isSearchFilterModalOpen,
    }));
  };

  const handleSearchBar = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchingFor: e.target.value,
    }));
  };

  return (
    <>
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "5em auto",
        }}
      >
        {/* Header */}
        {/* Header Hack */}
        <div className="header"></div>
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={() => {
                navigate("/marketplace");
              }}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
              <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
                <CategorySharp
                  className={
                    filterKeys?.length > 0
                      ? "text-green-400 hover:cursor-pointer bg-transparent border-0"
                      : "hover:cursor-pointer bg-transparent border-0"
                  }
                  onClick={() => toggleSearchFilterModal()}
                />
              </div>
              <input
                className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba caret-black"
                placeholder={"Que cherchez vous ?"}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
                onChange={(e) => handleSearchBar(e)}
                type="text"
                value={state.searchingFor}
                name="searchingFor"
                ref={inputRef}
                style={{
                  fontFamily: "Leckerli One",
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full m-auto">
          <div className="flex flex-wrap py-5 justify-evenly w-full">
            <div
              onClick={() => navigate("/desktop/shops/1")}
              className="w-2/5 mb-7 rounded-md border-2 hover:border-0 hover:cursor-pointer flex flex-col justify-between hover:shadow-2xl transition-all ease-linear duration-300"
            >
              <div className="flex justify-center items-center border-0">
                <img
                  src="https://otemcosmetics.com/img/my-shop-logo-1591169774.jpg"
                  className="rounded-md w-full object-scale-down"
                  alt="meta's logo"
                />
              </div>
              <div className="flex justify-center items-center border border-b-0 w-full">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  Otem cosmetics
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shops;
