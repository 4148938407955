// useFacebookPixel.ts
import { useEffect } from "react";

const useFacebookPixel = (pixelId) => {
  useEffect(() => {
    if (!window.fbq) return;
    window.fbq("init", pixelId);
    window.fbq("track", "PageView");
  }, [pixelId]);

  const trackEvent = (eventName, parameters) => {
    if (window.fbq) {
      window.fbq("track", eventName, parameters);
    }
  };

  const trackCustomEvent = (eventName, parameters) => {
    if (window.fbq) {
      window.fbq("trackCustom", eventName, parameters);
    }
  };

  return { trackEvent, trackCustomEvent };
};

export default useFacebookPixel;
