import React, { useCallback, useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

interface Props {
  images?: any[];
}

let count = 0;
let slideInterval;

const Slider = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideRef = useRef<HTMLDivElement>(null);

  const images = props?.images
    ? props?.images
    : [
        "https://i.pinimg.com/474x/df/8e/1c/df8e1cfdcf0509c6d6bb6b0ed7951c1c.jpg",
        "https://i.pinimg.com/474x/5b/94/73/5b947388db205dfdb3b1ef20a794cb1f.jpg",
        "https://i.pinimg.com/474x/4d/47/0d/4d470d8b2981865d4f1f953d52195501.jpg",
        "https://i.pinimg.com/474x/3f/9d/36/3f9d3653632fb0848360bfd7f4a2f5d1.jpg",
        "https://i.pinimg.com/474x/89/00/2d/89002dd2baf29bb369a54b653cdb4631.jpg",
        "https://i.pinimg.com/474x/4a/21/64/4a2164a8b853bb39221f32e8cce00ea5.jpg",
      ];

  const handleChevronLeft = () => {
    const size = images.length;
    count = (currentIndex + size - 1) % size;
    setCurrentIndex(count);
  };

  const handleChevronRight = useCallback(() => {
    count = (count + 1) % images.length;
    setCurrentIndex(count);
  }, [images.length]);

  const startSlider = useCallback(() => {
    slideInterval = setInterval(() => {
      handleChevronRight();
    }, 100000);
  }, [handleChevronRight]);

  useEffect(() => {
    startSlider();
    if (slideRef.current) {
      slideRef.current.addEventListener("mouseenter", pauseSlider);
      slideRef.current.addEventListener("mouseleave", startSlider);
    }

    return () => {
      clearInterval(slideInterval);
    };
  }, [startSlider]);

  const pauseSlider = () => {
    clearInterval(slideInterval);
  };

  return (
    <div className="flex items-center justify-center content">
      <div ref={slideRef} className="w-full select-none relative">
        <div className="w-full flex-row flex justify-evenly items-center">
          <img
            src={images[(currentIndex + images.length - 1) % images.length]}
            alt=""
            className="h-56 w-full md:w-1/4 object-contain aspect-video hidden md:flex"
          />
          <img
            src={images[currentIndex]}
            alt=""
            className="h-full w-full md:w-1/4 object-contain aspect-video"
          />
          <img
            src={images[(currentIndex + images.length + 1) % images.length]}
            alt=""
            className="h-56 w-full md:w-1/4 object-contain aspect-video hidden md:flex"
          />
        </div>
        <div className="absolute w-full top-1/2 transform -translate-y-1/2 px-3 flex justify-between items-center">
          <ChevronLeft
            onClick={() => handleChevronLeft()}
            className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
          />
          <ChevronRight
            onClick={() => handleChevronRight()}
            className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default Slider;
