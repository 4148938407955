import React from "react";
import logo from "./../../assets/logo/logo.png";
import { LinkedIn, Instagram, Facebook } from "@mui/icons-material";

interface Props {}

function Footer(props: Props) {
  const handleJumpToTop = (e) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-djeliba  font-bold text-black footer">
      <div className="container mx-auto px-4 py-8">
        {/* Top section: Logo, tagline, navigation, social icons */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo & Tagline */}
          <div className="flex items-center">
            <button
              onClick={handleJumpToTop}
              className="focus:outline-none mr-4"
            >
              <img
                src={logo}
                alt="Djeliba logo"
                className="h-16 object-scale-down cursor-pointer"
              />
            </button>
            <p className="hidden md:block text-sm">
              Le meilleur des marchés d'Afrique.
            </p>
          </div>

          {/* Navigation Links
          <div className="flex flex-wrap justify-center my-4 md:my-0">
            <a href="/about" className="mx-2 text-sm hover:underline">
              About
            </a>
            <a href="/contact" className="mx-2 text-sm hover:underline">
              Contact
            </a>
            <a href="/privacy" className="mx-2 text-sm hover:underline">
              Privacy Policy
            </a>
            <a href="/terms" className="mx-2 text-sm hover:underline">
              Terms of Use
            </a>
          </div> */}

          {/* Social Icons */}
          <div className="flex justify-center">
            {[
              {
                href: "https://www.instagram.com/media_djeliba/",
                Icon: Instagram,
                label: "Instagram",
              },
              {
                href: "https://www.linkedin.com/in/dj%C3%A9liba-group-41966b22a/",
                Icon: LinkedIn,
                label: "LinkedIn",
              },
              {
                href: "https://www.facebook.com/Dj%C3%A9liba-109800218247485",
                Icon: Facebook,
                label: "Facebook",
              },
            ]?.map(({ href, Icon, label }) => (
              <a
                key={label}
                target="_blank"
                rel="noreferrer"
                href={href}
                className="mx-1 p-0.5"
                aria-label={label}
              >
                <Icon className="h-12" />
              </a>
            ))}
          </div>
        </div>

        {/* Bottom Row: Copyright & Back to Top */}
        <div className="mt-8 border-t border-gray-400 pt-4 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} Djeliba. All rights reserved.
          </p>
          <button
            onClick={handleJumpToTop}
            className="text-sm hover:underline mt-2 md:mt-0"
          >
            Retour en haut
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
