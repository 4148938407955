import React, { useEffect, useRef, useState } from "react";
import icon from "../../assets/logo/icon.png";
import { useNavigate } from "react-router-dom";
import logo from "./../../assets/logo/logo.png";
import WizardHeader from "../../components/ux/wizard-header";
import WizardContent from "../../components/ux/wizard-content";
import { useQuery } from "@apollo/client";
import { ME } from "../../api/users/queries";
import { setUser } from "../../features/users/usersSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import Banner from "../../components/layout/banner";
import { CategorySharp } from "@mui/icons-material";

interface Props {}

const BecomeSeller = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("access_token");
  const [state, setState] = useState({
    isSearchFilterModalOpen: false,
    searchingFor: "",
  });
  const { data } = useQuery(ME, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: token === null ? true : false,
    errorPolicy: "all",
    context: {
      headers: { authorization: `Bearer ${token}` },
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const filterKeys = useAppSelector(
    (state: RootState) => state.products.filterKeys
  );

  useEffect(() => {
    if (data) {
      dispatch(setUser(data?.me));
    }
  }, [data, dispatch]);

  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const toggleSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: !prevState.isSearchFilterModalOpen,
    }));
  };

  const handleSearchBar = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchingFor: e.target.value,
    }));
  };

  return (
    <>
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "5em auto",
        }}
      >
        {/* Header */}
        {/* Header Hack */}
        <div className="header"></div>
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={() => {
                navigate("/marketplace");
              }}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
              <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
                <CategorySharp
                  className={
                    filterKeys?.length > 0
                      ? "text-green-400 hover:cursor-pointer bg-transparent border-0"
                      : "hover:cursor-pointer bg-transparent border-0"
                  }
                  onClick={() => toggleSearchFilterModal()}
                />
              </div>
              <input
                className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba caret-black"
                placeholder={"Que cherchez vous ?"}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
                onChange={(e) => handleSearchBar(e)}
                type="text"
                value={state.searchingFor}
                name="searchingFor"
                ref={inputRef}
                style={{
                  fontFamily: "Leckerli One",
                }}
              />
            </div>
          </div>
        </div>

        <WizardHeader />
        <WizardContent />

        <div
          onClick={() => {
            navigate("/marketplace");
          }}
          className="hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none"
        >
          <img src={icon} alt="" className="object-scale-down rounded-full" />
        </div>
      </div>
    </>
  );
};

export default BecomeSeller;
