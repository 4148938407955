import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DeliveryState {
  isWaiting: boolean;
  error: any;
  deliveries: any[];
  filteredDeliveries: object[];
  filterKeys: string[];
  pageInfo: {
    startCursor: string;
    endCursor: string;
  };
  delivery: {
    shop: string;
    price: string;
    amount: string;
    addressA: string;
    nameA: string;
    phoneA: string;
    isPhoneAValid: boolean;
    isPhoneBValid: boolean;
    addressB: string;
    nameB: string;
    phoneB: string;
    quantity: string;
    description: string;
    categories: any[];
    isPriceValid: boolean;
    isAmountValid: boolean;
    isDeliveryStartAtA: boolean;
    isaddressAValid: boolean;
    currentIndex: number;
    isQuantityValid: boolean;
    isCategoriesValid: boolean;
    isDescriptionValid: boolean;
    isEditDeliveryModalOpen: boolean;
    isWrapUpModalOpened: boolean;
    isNewDeliveryModalOpen: boolean;
    isHideDeliveryModalOpen: boolean;
    isDeleteDeliveryModalOpen: boolean;
    action: string;
  };
  // activeDelivery: {
  //   _id: string;
  //   shop: string;
  //   price: string;
  //   amount: string;
  //   addressA: string;
  //   nameA: string;
  //   phoneA: string;
  //   isPhoneAValid: boolean;
  //   isPhoneBValid: boolean;
  //   addressB: string;
  //   nameB: string;
  //   phoneB: string;
  //   quantity: string;
  //   description: string;
  //   categories: any[];
  //   isPriceValid: boolean;
  //   isAmountValid: boolean;
  //   isDeliveryStartAtA: boolean;
  //   isaddressAValid: boolean;
  //   currentIndex: number;
  //   isQuantityValid: boolean;
  //   isCategoriesValid: boolean;
  //   isDescriptionValid: boolean;
  //   isEditDeliveryModalOpen: boolean;
  //   isWrapUpModalOpened: boolean;
  //   isNewDeliveryModalOpen: boolean;
  //   isUpdateDeliveryModalOpen: boolean;
  //   isHideDeliveryModalOpen: boolean;
  //   isDeleteDeliveryModalOpen: boolean;
  // };
}

const initialState: DeliveryState = {
  isWaiting: false,
  error: null,
  deliveries: [],
  filteredDeliveries: [],
  filterKeys: [],
  pageInfo: {
    startCursor: "",
    endCursor: "",
  },
  delivery: {
    addressA: "",
    nameA: "",
    phoneA: "",
    isPhoneAValid: false,
    isPhoneBValid: false,
    addressB: "",
    nameB: "",
    phoneB: "",
    price: "",
    amount: "",
    shop: "",
    quantity: "",
    categories: [],
    description: "",
    currentIndex: 0,
    isPriceValid: false,
    isAmountValid: false,
    isaddressAValid: false,
    isDeliveryStartAtA: false,
    isQuantityValid: false,
    isCategoriesValid: false,
    isDescriptionValid: false,
    isNewDeliveryModalOpen: false,
    isWrapUpModalOpened: false,
    isEditDeliveryModalOpen: false,
    isHideDeliveryModalOpen: false,
    isDeleteDeliveryModalOpen: false,
    action: "CREATE",
  },
  // activeDelivery: {
  //   _id: "",
  //   addressA: "",
  //   nameA: "",
  //   phoneA: "",
  //   isPhoneAValid: false,
  //   isPhoneBValid: false,
  //   addressB: "",
  //   nameB: "",
  //   phoneB: "",
  //   price: "",
  //   amount: "",
  //   shop: "",
  //   quantity: "",
  //   categories: [],
  //   description: "",
  //   currentIndex: 0,
  //   isPriceValid: false,
  //   isAmountValid: false,
  //   isaddressAValid: false,
  //   isDeliveryStartAtA: false,
  //   isQuantityValid: false,
  //   isCategoriesValid: false,
  //   isDescriptionValid: false,
  //   isNewDeliveryModalOpen: false,
  //   isWrapUpModalOpened: true,
  //   isEditDeliveryModalOpen: false,
  //   isUpdateDeliveryModalOpen: false,
  //   isHideDeliveryModalOpen: false,
  //   isDeleteDeliveryModalOpen: false,
  // },
};

export const deliverySlice = createSlice({
  name: "deliveries",
  initialState,
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    stopWaiting: (state) => {
      state.isWaiting = false;
    },
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.delivery.currentIndex = action.payload;
    },
    setFilterKeys: (state, action: PayloadAction<string>) => {
      if (
        state.filterKeys?.some(function (el) {
          return el === action.payload;
        })
      ) {
        state.filterKeys = state?.filterKeys?.filter(
          (el) => el !== action.payload
        );
      } else {
        if (typeof state.filterKeys === "undefined") {
          state.filterKeys = [action.payload];
        } else {
          state.filterKeys = [...state?.filterKeys, action.payload];
        }
      }
    },
    clearFilterKeys: (state) => {
      state.filterKeys = [];
    },
    setDeliveries: (state, action: PayloadAction<object[]>) => {
      state.deliveries = action.payload;
      state.filteredDeliveries = action.payload;
    },
    removeFromDeliveries: (state, action: PayloadAction<string>) => {
      state.deliveries = state.deliveries.filter(
        (item) => item?.node?._id !== action.payload
      );
    },
    setDeliveryAddressA: (state, action: PayloadAction<any>) => {
      state.delivery.addressA = action.payload;
    },
    setDeliveryNameA: (state, action: PayloadAction<any>) => {
      state.delivery.nameA = action.payload;
    },
    setDeliveryPhoneA: (state, action: PayloadAction<any>) => {
      state.delivery.phoneA = action.payload;
    },
    setPhoneAVaidity: (state, action: PayloadAction<any>) => {
      state.delivery.isPhoneAValid = action.payload;
    },
    setPhoneBVaidity: (state, action: PayloadAction<any>) => {
      state.delivery.isPhoneBValid = action.payload;
    },
    setDeliveryAddressB: (state, action: PayloadAction<any>) => {
      state.delivery.addressB = action.payload;
    },
    setDeliveryNameB: (state, action: PayloadAction<any>) => {
      state.delivery.nameB = action.payload;
    },
    setDeliveryPhoneB: (state, action: PayloadAction<any>) => {
      state.delivery.phoneB = action.payload;
    },
    setDeliveryPrice: (state, action: PayloadAction<any>) => {
      const price = parseInt(action.payload.replace(/[\D\s-]/g, ""), 10);
      state.delivery.price = new Intl.NumberFormat("fr-FR").format(price || 0);
      state.delivery.isPriceValid = price >= 500 && price < 5000;
    },
    setOrderAmount: (state, action: PayloadAction<any>) => {
      const amount = parseInt(action.payload.replace(/[\D\s-]/g, ""), 10);
      state.delivery.amount = new Intl.NumberFormat("fr-FR").format(
        amount || 0
      );
      state.delivery.isAmountValid = amount > 500 && amount < 10000000;
      console.log(state.delivery.amount);
      console.log(amount);
      console.log(Number(state.delivery.amount));
      console.log(parseInt(state.delivery.amount.replace(/[\D\s-]/g, ""), 10));
    },
    setDeliveryDescription: (state, action: PayloadAction<any>) => {
      state.delivery.description = action.payload;
      state.delivery.isDescriptionValid = action.payload?.trim().length <= 500;
      console.log(state.delivery.description.length > 5);
    },
    setDeliveryStart: (state, action: PayloadAction<boolean>) => {
      state.delivery.isDeliveryStartAtA = action.payload;
    },
    toggleNewDeliveryModal: (state) => {
      state.delivery.isNewDeliveryModalOpen =
        !state.delivery.isNewDeliveryModalOpen;
    },
    // toggleUpdateDeliveryModal: (state) => {
    //   state.activeDelivery.isUpdateDeliveryModalOpen =
    //     !state.activeDelivery.isUpdateDeliveryModalOpen;
    // },
    toggleWrapUpModal: (state) => {
      state.delivery.isNewDeliveryModalOpen =
        !state.delivery.isNewDeliveryModalOpen;
    },
    dismissNewDeliveryModal: (state) => {
      state.delivery.isNewDeliveryModalOpen = false;
    },
    // dismissUpdateDeliveryModal: (state) => {
    //   state.activeDelivery.isUpdateDeliveryModalOpen = false;
    // },
    dismissWrapUpModal: (state) => {
      state.delivery.isWrapUpModalOpened = false;
    },
    toggleDeleteDeliveryModal: (state) => {
      state.delivery.isDeleteDeliveryModalOpen =
        !state.delivery.isDeleteDeliveryModalOpen;
    },
    dismissDeleteDeliveryModal: (state) => {
      state.delivery.isDeleteDeliveryModalOpen = false;
    },
    toggleHideDeliveryModal: (state) => {
      state.delivery.isHideDeliveryModalOpen =
        !state.delivery.isHideDeliveryModalOpen;
    },
    dismissHideDeliveryModal: (state) => {
      state.delivery.isHideDeliveryModalOpen = false;
    },
    setActiveDelivery: (state, action: PayloadAction<any>) => {
      state.delivery = {
        ...state.delivery,
        ...action.payload,
      };
    },
    filterDeliveries: (state, action: PayloadAction<any>) => {
      switch (action?.payload) {
        case "DELIVERED":
          state.filteredDeliveries = state.deliveries.filter((item) => {
            let state = item?.node?.state.toLowerCase().replace(/ /g, "");
            let text = action.payload.toLowerCase().replace(/ /g, "");
            return state.normalize() === text.normalize() ? item : null;
          });
          break;
        case "PENDING":
          state.filteredDeliveries = state.deliveries.filter((item) => {
            let alike = "PREPARATION";
            let done = "DELIVERED";
            let state = item?.node?.state.toLowerCase().replace(/ /g, "");
            let status = item?.node?.status.toLowerCase().replace(/ /g, "");
            alike = alike.toLowerCase().replace(/ /g, "");
            done = done.toLowerCase().replace(/ /g, "");
            let text = action.payload.toLowerCase().replace(/ /g, "");
            return (status.normalize() === text.normalize() ||
              status.normalize() === alike.normalize()) &&
              state.normalize() !== done.normalize()
              ? item
              : null;
          });
          break;
        case "CANCELED":
          state.filteredDeliveries = state.deliveries.filter((item) => {
            let done = "DELIVERED";
            let state = item?.node?.state.toLowerCase().replace(/ /g, "");
            done = done.toLowerCase().replace(/ /g, "");
            let text = action.payload.toLowerCase().replace(/ /g, "");
            return state.normalize().includes(text.normalize()) &&
              state.normalize() !== done.normalize()
              ? item
              : null;
          });
          break;

        default:
          state.filteredDeliveries = state.deliveries.filter((item) => {
            let done = "DELIVERED";
            let status = item?.node?.status.toLowerCase().replace(/ /g, "");
            let state = item?.node?.state.toLowerCase().replace(/ /g, "");
            done = done.toLowerCase().replace(/ /g, "");
            let text = action.payload.toLowerCase().replace(/ /g, "");
            return status.normalize().includes(text.normalize()) &&
              state.normalize() !== done.normalize()
              ? item
              : null;
          });
          break;
      }
    },
    searchFor: (state, action: PayloadAction<any>) => {
      if (!(action?.payload?.length === 0)) {
        state.filteredDeliveries = state.deliveries.filter((item) => {
          let phoneA = item?.node?.phoneA.toLowerCase().replace(/ /g, "");
          let phoneB = item?.node?.phoneB.toLowerCase().replace(/ /g, "");
          let text = action.payload.toLowerCase().replace(/ /g, "");
          return phoneA.normalize().includes(text.normalize()) ||
            phoneB.normalize().includes(text.normalize())
            ? item
            : null;
        });
      } else {
        state.filteredDeliveries = state.deliveries;
      }
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isWaiting = false;
    },
    clearStorage() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
  },
  extraReducers: (builder) => {},
});

export const {
  hasError,
  setDeliveries,
  stopWaiting,
  startWaiting,
  clearStorage,
  setDeliveryPrice,
  setOrderAmount,
  setDeliveryAddressA,
  setDeliveryNameA,
  setDeliveryPhoneA,
  filterDeliveries,
  setDeliveryAddressB,
  setDeliveryNameB,
  setDeliveryPhoneB,
  setCurrentIndex,
  setActiveDelivery,
  setFilterKeys,
  removeFromDeliveries,
  searchFor,
  clearFilterKeys,
  setDeliveryStart,
  setDeliveryDescription,
  toggleNewDeliveryModal,
  toggleHideDeliveryModal,
  dismissNewDeliveryModal,
  setPhoneAVaidity,
  setPhoneBVaidity,
  toggleWrapUpModal,
  dismissWrapUpModal,
  dismissHideDeliveryModal,
  toggleDeleteDeliveryModal,
  dismissDeleteDeliveryModal,
} = deliverySlice.actions;

export default deliverySlice.reducer;
