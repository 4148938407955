import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="max-w-md p-6 text-center bg-white shadow-lg rounded-lg">
        <h1 className="text-4xl font-bold text-red-600">Oops!</h1>
        <p className="mt-2 text-lg text-gray-700">
          Une erreur inattendue s'est produite.
        </p>
        <p className="mt-2 text-gray-500 italic">
          {error?.statusText || error?.message}
        </p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Recharger la page
        </button>
        <a
          href="/marketplace"
          className="mt-2 block text-blue-500 hover:underline"
        >
          Retour à l'acceuil
        </a>
      </div>
    </div>
  );
}
