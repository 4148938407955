import { RootState } from "../../app/store";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/modal";
import NewDelivery from "../../components/ux/new-delivery";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  dismissNewDeliveryModal,
  dismissWrapUpModal,
  filterDeliveries,
  removeFromDeliveries,
  setActiveDelivery,
  setDeliveries,
  toggleNewDeliveryModal,
  toggleWrapUpModal,
} from "../../features/deliveries/deliveriesSlice";
import DeliveryWrapUp from "../../components/common/new-delivery-wrap-up";
import { formatPhone } from "../../utils/format";
import { MdSouth } from "react-icons/md";
import { classNames, useWindowDimensions } from "../../utils/utils";
import { DELIVERIES_BY_OWNER } from "../../api/deliveries/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { DeleteForeverSharp, EditSharp } from "@material-ui/icons";
import { REMOVE_DELIVERY } from "../../api/deliveries/mutations";

interface Props {}

const Deliveries = (props: Props) => {
  const [state, setState] = useState<any>({
    isWaiting: false,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useAppSelector((state: RootState) => state.utils.location);
  const delivery = useAppSelector(
    (state: RootState) => state.deliveries.delivery
  );

  const allDeliveries = useAppSelector(
    (state: RootState) => state.deliveries.deliveries
  );
  const deliveries = useAppSelector(
    (state: RootState) => state.deliveries.filteredDeliveries
  );
  const logged = useAppSelector((state: RootState) => state.utils.logged);

  const { data } = useQuery(DELIVERIES_BY_OWNER, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "all",
    skip: !logged,
    variables: {
      first: 50,
    },
  });

  useEffect(() => {
    dispatch(setDeliveries(data?.deliveriesByOwner?.page?.edges));
  }, [data, dispatch]);

  const [removeDelivery] = useMutation(REMOVE_DELIVERY, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        dispatch(removeFromDeliveries(data?.removeDelivery?._id));
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleEditDelivery = (e, product) => {
    e.preventDefault();
    dispatch(setActiveDelivery({ ...product, action: "EDIT" }));
    dispatch(toggleNewDeliveryModal());
  };

  const handleSumbit = (e, id) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    removeDelivery({
      variables: {
        id,
      },
    });
  };

  return (
    <>
      <Modal
        content={<NewDelivery />}
        className="w-11/12 md:w-3/5"
        modalIsOpen={delivery?.isNewDeliveryModalOpen}
        handleModal={() => dispatch(toggleNewDeliveryModal())}
        dismissModal={() => dispatch(dismissNewDeliveryModal())}
      />
      <Modal
        content={<DeliveryWrapUp />}
        className="w-11/12 md:w-3/5"
        modalIsOpen={delivery?.isWrapUpModalOpened}
        handleModal={() => dispatch(toggleWrapUpModal())}
        dismissModal={() => dispatch(dismissWrapUpModal())}
      />
      <div className="m-auto flex flex-col justify-center w-full h-full scrollbar-hidden pb-10">
        <div className="flex overflow-x-auto scrollbar-hidden justify-start md:justify-center items-center w-full max-h-40 pt-7 m-2 mr-2 border rounded-md border-djeliba">
          <div
            className="flex flex-col justify-center font-bold items-center w-3/5 h-32 p-2 m-2 hover:border hover:cursor-pointer shadow-xl hover:shadow-transparent hover:border-djeliba rounded-md"
            onClick={() => dispatch(filterDeliveries("PENDING"))}
          >
            <div className="flex p-1 w-28 justify-center items-center">
              En Attente
            </div>
            <div className="flex justify-center items-center bg-djeliba rounded-full w-10 h-10 p-1">
              <span className="">
                {
                  allDeliveries?.filter(
                    (item) =>
                      (item?.node?.state !== "DELIVERED" &&
                        item?.node?.status == "PREPARATION") ||
                      (item?.node?.state !== "DELIVERED" &&
                        item?.node?.status == "PENDING")
                  ).length
                }
              </span>
            </div>
          </div>
          <div
            className="flex flex-col justify-center font-bold  items-center w-3/5 h-32 p-2 m-2 hover:border hover:cursor-pointer shadow-xl hover:shadow-transparent hover:border-djeliba rounded-md"
            onClick={() => dispatch(filterDeliveries("DISPATCH"))}
          >
            <div className="flex p-1 w-28 justify-center items-center">
              En Cours
            </div>
            <div className="flex justify-center items-center bg-djeliba rounded-full w-10 h-10 p-1">
              <span className="">
                {
                  allDeliveries?.filter(
                    (item) =>
                      item?.node?.state != "DELIVERED" &&
                      item?.node?.status == "DISPATCH"
                  ).length
                }
              </span>
            </div>
          </div>
          <div
            className="flex flex-col justify-center font-bold items-center w-3/5 h-32 p-2 m-2 hover:border hover:cursor-pointer shadow-xl hover:shadow-transparent hover:border-djeliba rounded-md"
            onClick={() => dispatch(filterDeliveries("DELIVERED"))}
          >
            <div className="flex p-1 w-28 justify-center items-center">
              Terminé
            </div>
            <div className="flex justify-center items-center bg-djeliba rounded-full w-10 h-10 p-1">
              <span className="">
                {
                  allDeliveries?.filter(
                    (item) => item?.node?.state == "DELIVERED"
                  ).length
                }
              </span>
            </div>
          </div>
          <div
            className="flex flex-col justify-center font-bold items-center w-3/5 h-32 p-2 m-2 hover:border hover:cursor-pointer shadow-xl hover:shadow-transparent hover:border-djeliba rounded-md"
            onClick={() => dispatch(filterDeliveries("UNAVAILABLE"))}
          >
            <div className="flex p-1 w-28 justify-center items-center">
              Indisponible
            </div>
            <div className="flex justify-center items-center bg-djeliba rounded-full w-10 h-10 p-1">
              <span className="">
                {
                  allDeliveries?.filter(
                    (item) =>
                      item?.node?.state != "DELIVERED" &&
                      item?.node?.status == "UNAVAILABLE"
                  ).length
                }
              </span>
            </div>
          </div>
          <div
            className="flex flex-col justify-center font-bold items-center w-3/5 h-32 p-2 m-2 hover:border hover:cursor-pointer shadow-xl hover:shadow-transparent hover:border-djeliba rounded-md"
            onClick={() => dispatch(filterDeliveries("UNREACHABLE"))}
          >
            <div className="flex p-1 w-28 justify-center items-center">
              Injoignable
            </div>
            <div className="flex justify-center items-center bg-djeliba rounded-full w-10 h-10 p-1">
              <span className="">
                {
                  allDeliveries?.filter(
                    (item) =>
                      item?.node?.state != "DELIVERED" &&
                      item?.node?.status == "UNREACHABLE"
                  ).length
                }
              </span>
            </div>
          </div>
          <div
            className="flex flex-col justify-center font-bold items-center w-3/5 h-32 p-2 m-2 hover:border hover:cursor-pointer shadow-xl hover:shadow-transparent hover:border-djeliba rounded-md"
            onClick={() => dispatch(filterDeliveries("CANCELED"))}
          >
            <div className="flex p-1 w-28 justify-center items-center">
              Annulé
            </div>
            <div className="flex justify-center items-center bg-djeliba rounded-full w-10 h-10 p-1">
              <span className="">
                {
                  allDeliveries?.filter(
                    (item) => item?.node?.state == "CANCELED"
                  ).length
                }
              </span>
            </div>
          </div>
          <div
            className="flex flex-col justify-center font-bold items-center w-3/5 h-32 p-2 m-2 hover:border hover:cursor-pointer shadow-xl hover:shadow-transparent hover:border-djeliba rounded-md"
            onClick={() => dispatch(filterDeliveries("MORE"))}
          >
            <div className="flex p-1 w-28 justify-center items-center">
              Autres
            </div>
            <div className="flex justify-center items-center bg-djeliba rounded-full w-10 h-10 p-1">
              <span className="">
                {
                  allDeliveries?.filter(
                    (item) =>
                      item?.node?.state != "DELIVERED" &&
                      item?.node?.status == "MORE"
                  ).length
                }
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col scrollbar-hidden justify-start font-bold items-center w-full h-full p-2 m-2 mr-2 border rounded-md border-djeliba overflow-y-scroll scrollbar-thin">
          {deliveries?.length === 0 ? (
            <div className="flex flex-col justify-between items-center text-center w-full p-2">
              <span className="text-lg text-slate-400 mt-32 font-bold">
                Il n'y a aucune livraison pour le moment.
              </span>
            </div>
          ) : (
            <>
              {deliveries?.map((delivery, i) => (
                <div 
                key={i}
                className="flex justify-center items-center w-full md:w-5/6 h-40 border border-djeliba p-2 m-2 hover:shadow-xl relative transition-all ease-linear duration-300 group/card">
                  <div
                    className={
                      delivery?.node?.state == "DELIVERED"
                        ? classNames(
                            "flex-col md:m-1 md:p-1 bg-green-200 flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.state == "CANCELED"
                        ? classNames(
                            "flex-col md:m-1 md:p-1 bg-red-400 flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "PREPARATION" ||
                          delivery?.node?.status == "PENDING"
                        ? classNames(
                            "flex-col md:m-1 md:p-1 bg-slate-200 flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "DISPATCH"
                        ? classNames(
                            "flex-col md:m-1 md:p-1 bg-blue-200 flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "UNAVAILABLE"
                        ? classNames(
                            "flex-col md:m-1 md:p-1 bg-yellow-200 flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "UNREACHABLE"
                        ? classNames(
                            "flex-col md:m-1 md:p-1 bg-red-200 flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "MORE"
                        ? classNames(
                            "flex-col md:m-1 md:p-1 bg-gray-200 flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : ""
                    }
                  >
                    <div className="flex justify-center items-center bg-white rounded-md m-1">
                      <span className="font-thin m-1 p-1">
                        {delivery?.node?.addressA}
                      </span>
                      <span className="font-thin m-1 p-1">|</span>
                      <span className="font-thin m-1 p-1">
                        {delivery?.node?.nameA}
                      </span>
                      <span className="font-thin m-1 p-1">-</span>
                      <span className="font-thin m-1 p-1">
                        {formatPhone(
                          location?.country_code2,
                          delivery?.node?.phoneA
                        )}
                      </span>{" "}
                    </div>
                    <div className="flex justify-center items-center bg-white rounded-md m-1">
                      <MdSouth />
                    </div>
                    <div className="flex justify-center items-center bg-white rounded-md m-1">
                      <span className="font-thin m-1 p-1">
                        {delivery?.node?.addressB}
                      </span>
                      <span className="font-thin m-1 p-1">|</span>
                      <span className="font-thin m-1 p-1">
                        {delivery?.node?.nameB}
                      </span>
                      <span className="font-thin m-1 p-1">-</span>
                      <span className="font-thin m-1 p-1">
                        {formatPhone(
                          location?.country_code2,
                          delivery?.node?.phoneB
                        )}
                      </span>{" "}
                    </div>
                  </div>
                  <div
                    className={
                      delivery?.node?.state == "DELIVERED"
                        ? classNames(
                            " bg-green-200 hidden md:flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.state == "CANCELED"
                        ? classNames(
                            " bg-red-400 hidden md:flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "PREPARATION" ||
                          delivery?.node?.status == "PENDING"
                        ? classNames(
                            " bg-slate-200 hidden md:flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "DISPATCH"
                        ? classNames(
                            " bg-blue-200 hidden md:flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "UNAVAILABLE"
                        ? classNames(
                            " bg-yellow-200 hidden md:flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "UNREACHABLE"
                        ? classNames(
                            " bg-red-200 hidden md:flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : delivery?.node?.status == "MORE"
                        ? classNames(
                            " bg-gray-200 hidden md:flex justify-center items-center w-full h-full m-1 p-1 rounded-md"
                          )
                        : ""
                    }
                  >
                    <div className="flex flex-col justify-evenly items-start w-full h-full">
                      <div className="flex justify-start m-1 items-center rounded-md bg-white">
                        <span className="font-bold capitalize p-1">
                          Commande :
                        </span>
                        <span className="font-thin p-1">15500 F cfa</span>
                      </div>
                      <div className="flex justify-start m-1 items-center rounded-md bg-white">
                        <span className="font-bold capitalize p-1">
                          Livraison :
                        </span>
                        <span className="font-thin p-1">
                          {" "}
                          {delivery?.node?.deliveryAmount} F cfa
                        </span>
                      </div>
                      <div className="flex justify-start m-1 items-center rounded-md bg-white">
                        <span className="font-bold capitalize p-1">
                          Total :
                        </span>
                        <span className="font-thin p-1"> 17000 F cfa</span>
                      </div>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full h-full">
                      <div className="bg-white rounded-md m-2">
                        <span className="font-bold capitalize p-1">
                          Status :
                        </span>
                        <span className="font-thin p-1">
                          {delivery?.node?.state == "DELIVERED"
                            ? "Terminé"
                            : delivery?.node?.state == "CANCELED"
                            ? "Annulé"
                            : delivery?.node?.status == "PREPARATION" ||
                              delivery?.node?.status == "PENDING"
                            ? "En Attente"
                            : delivery?.node?.status == "DISPATCH"
                            ? "En Cours"
                            : delivery?.node?.status == "UNAVAILABLE"
                            ? "Indisponible"
                            : delivery?.node?.status == "UNREACHABLE"
                            ? "Injoignable"
                            : delivery?.node?.status == "MORE"
                            ? "Autres"
                            : null}
                        </span>
                      </div>
                      {delivery?.node?.description?.length > 0 && (
                        <div className="bg-white rounded-md m-2 flex flex-col justify-start items-start min-w-fit max-h-80 overflow-x-hidden scrollbar-thin scrollbar-black-thumb">
                          <span className="font-bold capitalize p-1">
                            Détails :
                          </span>
                          <span className="capitalize font-thin m-1 p-1">
                            {delivery?.node?.description}
                          </span>
                        </div>
                      )}
                      {delivery?.node?.state !== "DELIVERED" && (
                        <div className="absolute flex flex-col p-1 m-1 right-1 bottom-1 invisible group-hover/card:visible rounded-md px-1 bg-white">
                          <div className="relative transition-all ease-linear duration-300 group/icon">
                            <EditSharp
                              className="hover:cursor-pointer bg-black text-white rounded-md mb-2"
                              onClick={(e) =>
                                handleEditDelivery(e, delivery?.node)
                              }
                            />
                            <div className="absolute border border-djeliba m-1 flex right-7 bottom-2 min-w-max  invisible group-hover/icon:visible rounded-md px-1 bg-white ">
                              <span className="text-xs font-thin">
                                Modifier
                              </span>
                            </div>
                          </div>
                          <div className="relative transition-all ease-linear duration-300 group/icon">
                            <DeleteForeverSharp
                              className="hover:cursor-pointer bg-black text-white rounded-md mb-2"
                              onClick={(e) =>
                                handleSumbit(e, delivery?.node?._id)
                              }
                            />
                            <div className="absolute border border-djeliba m-1 flex right-7 bottom-2 min-w-max  invisible group-hover/icon:visible rounded-md px-1 bg-white ">
                              <span className="text-xs font-thin">
                                Supprimer
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Deliveries;
