import Button from "../form/button";
import toast from "react-hot-toast";
import InputField from "../form/input";
import { RootState } from "../../app/store";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { resetCart } from "../../features/cart/cartSlice";
import { setUser } from "../../features/users/usersSlice";
import { CREATE_ORDER } from "../../api/orders/mutations";
import { isOTPValid, phoneValidity } from "../../utils/validation";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { formatOTP, formatPhone, removeSpace } from "../../utils/format";
import { CREATE_PAYMENT, PAYMENT } from "../../api/payments/mutations";

interface Props {
  handleDismiss: any;
  item?: any;
}

const Validation = (props: Props) => {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.users.user);
  const location = useAppSelector((state: RootState) => state.utils.location);
  const [state, setState] = useState({
    phone:
      user?.phone === null
        ? ""
        : formatPhone(location?.country_code2, user?.phone),
    isPhoneValidated: true,
    isCodeValid: true,
    isOTPSent: false,
    code: "",
    isWaiting: false,
    isMobile: true,
  });
  const calling_code = useAppSelector(
    (state: RootState) => state.utils.location.calling_code
  );

  const paymentMode = useAppSelector(
    (state: RootState) => state.payments.payment.paymentMode
  );
  let cartItems = useAppSelector((state: RootState) => state.cart.cartItems);
  if (props.item) {
    cartItems = [props.item];
  }
  const [payment] = useMutation(PAYMENT, {
    onCompleted: (data) => {
      if (data) {
        const { success } = data?.payment;
        switch (paymentMode) {
          case "OM":
            if (success) {
              toast.success(
                "Veuillez vous assurer que votre solde est suffisant."
              );
            } else {
              toast.error("Erreur lors du paiement veuillez réessayer.");
            }
            break;
          case "WAVE":
            if (success) {
              const { url } = data?.payment;
              toast.success(
                "Veuillez valider votre paiement avec votre application wave."
              );
              window.open(url, "_blank");
            } else {
              toast.error("Erreur lors du paiement veuillez réessayer.");
            }
            break;
          default:
            break;
        }
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        switch (paymentMode) {
          case "OM":
            payment({
              variables: {
                paymentInput: {
                  token: data.createPayment.token,
                  phone: removeSpace(state.phone),
                  code: removeSpace(state.code),
                  calling_code,
                  paymentMode: "OM",
                },
              },
            });
            dispatch(props.handleDismiss());
            dispatch(resetCart());
            toast.success("Votre commande à été prise en compte");
            toast.success("Veuillez attendre l'appel du livreur");
            break;
          case "WAVE":
            payment({
              variables: {
                paymentInput: {
                  token: data.createPayment.token,
                  phone: removeSpace(state.phone),
                  calling_code,
                  paymentMode: "WAVE",
                },
              },
            });
            dispatch(props.handleDismiss());
            dispatch(resetCart());
            toast.success("Votre commande à été prise en compte");
            toast.success("Veuillez attendre l'appel du livreur");
            break;
          default:
            break;
        }
        navigate("/orders");
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const [submitOrder] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        switch (paymentMode) {
          case "CASH":
            dispatch(resetCart());
            navigate("/marketplace/orders");
            toast.success("Votre commande à été prise en compte");
            toast.success("Veuillez attendre l'appel du livreur");
            dispatch(props.handleDismiss());
            break;
          case "OM":
            createPayment({
              variables: {
                paymentMode: "OM",
                orderId: data.createOrder._id,
                amount: data.createOrder.amount,
                phone: removeSpace(state.phone),
                calling_code,
              },
            });
            break;
          case "WAVE":
            createPayment({
              variables: {
                paymentMode: "WAVE",
                orderId: data.createOrder._id,
                amount: data.createOrder.amount,
                phone: removeSpace(state.phone),
                calling_code,
              },
            });
            break;
          default:
            break;
        }
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const dispatch = useAppDispatch();

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "phone":
        setState((prevState) => ({
          ...prevState,
          isPhoneValidated: phoneValidity(
            location?.country_code2,
            e.target.value
          ),
          [e.target.name]: formatPhone(location?.country_code2, e.target.value),
        }));
        dispatch(setUser({ phone: removeSpace(e.target.value) }));
        break;
      case "code":
        setState((prevState) => ({
          ...prevState,
          isCodeValid: isOTPValid(e.target.value),
          [e.target.name]: formatOTP(e.target.value),
        }));
        break;
      default:
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    submitOrder({
      variables: {
        phone: removeSpace(state.phone),
        calling_code,
        products: cartItems?.map((item, i) => ({
          product: item._id,
          quantity: item.quantity,
          shop: item.shop._id,
        })),
      },
    });
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
    }));
  }, []);

  return (
    <>
      <div className="bg-gray-50 flex justify-center items-center flex-col py-7">
        <div className="flex justify-center items-center bg-white w-full h-12 ">
          <span className="font-bold text-2xl">
            {
              {
                CASH: "Numéro de téléphone",
                OM: "Payé par Orange Money",
                WAVE: "Payé par Wave",
              }[paymentMode]
            }
          </span>
        </div>
        <div className="p-3 pb-0 flex justify-center items-start flex-col">
          <InputField
            id="phone"
            type="tel"
            autoFocus={true}
            labelName="phone"
            value={state.phone}
            icon={location?.country_flag}
            calling_code={location?.calling_code}
            placeholder="Entrez votre numéro ici"
            handleInputChange={handleInputChange}
            isValid={
              state.phone.length === 0
                ? true
                : state.isPhoneValidated
                ? true
                : false
            }
            info={location?.country_code2.toLocaleUpperCase()}
          />
          {paymentMode === "OM" && state.isPhoneValidated && (
            <div className="flex justify-center items-center bg-white rounded-md flex-col p-2">
              <span className="text-center">
                Tapez{" "}
                <span className="text-gray-500 text-sm">
                  <span className="text-green-600">#</span>144
                  <span className="text-yellow-500">#</span>391
                  <span className="text-green-600">*</span>VOTRE_CODE_OM
                  <span className="text-red-600">#</span>
                </span>{" "}
                pour générer le code de la transaction{" "}
              </span>
            </div>
          )}
          <InputField
            id="code"
            type="text"
            hidden={!state.isPhoneValidated || !(paymentMode === "OM")}
            value={state.code}
            isValid={state.isCodeValid ? true : false}
            labelName="code"
            placeholder="Tapez le code reçu par sms"
            handleInputChange={handleInputChange}
            styles={"w-full"}
          />
        </div>
        <div className="w-1/2">
          <Button
            title="Valider"
            isWaiting={state.isWaiting}
            handleOnClick={handleSubmit}
            disabled={
              !state.isPhoneValidated ||
              state.isWaiting ||
              (paymentMode === "OM" && state.code.length === 0
                ? true
                : !state.isCodeValid
                ? true
                : false)
            }
          />
        </div>
      </div>
    </>
  );
};

export default Validation;
