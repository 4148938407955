import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { RootState } from "../../app/store";
import Slider from "../../components/ux/slider";
import logo from "./../../assets/logo/logo.png";
import Button from "../../components/form/button";
import Modal from "../../components/common/modal";
import { CategorySharp } from "@mui/icons-material";
import Banner from "../../components/layout/banner";
import Footer from "../../components/layout/footer";
import { PRODUCTS } from "../../api/product/queries";
import Content from "../../components/layout/content";
import { useIntersectionObserver } from "usehooks-ts";
import SearchFilter from "../../components/ux/search-filter";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { searchFor, setProducts } from "../../features/products/productsSlice";
import { ReactComponent as Spinner } from "./../../assets/spinner/spinner.svg";
import useFacebookPixel from "../../hooks/useFacebookPixel";

function Home() {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    isSearchFilterModalOpen: false,
    searchingFor: "",
  });
  const { trackEvent, trackCustomEvent } = useFacebookPixel("2318743751858547");
  const filterKeys = useAppSelector(
    (state: RootState) => state.products.filterKeys
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const toggleSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: !prevState.isSearchFilterModalOpen,
    }));
  };
  const dismissSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: false,
    }));
  };

  const handleSearchBar = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchingFor: e.target.value,
    }));

    dispatch(searchFor(e.target.value));
  };
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  const products = useAppSelector((state) => state.products.filteredProducts);

  let first = 50;

  const { data, loading, called, fetchMore, networkStatus, refetch } = useQuery(
    PRODUCTS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      errorPolicy: "all",
      variables: {
        first,
      },
    }
  );

  const hasNextPage = data?.products?.page?.pageInfo?.hasNextPage;
  const isRefetching = networkStatus === 3;

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch(); // Trigger the refetch
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [refetch]);
  
  useEffect(() => {
    const ids = products?.map((obj) => obj?.node?._id);
    trackEvent("ViewContent", {
      content_ids: ids, // 'REQUIRED': array of product IDs
      content_type: "product", // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
    });
  }, []);

  useEffect(() => {
    dispatch(setProducts(data?.products?.page?.edges));
  }, [data, dispatch]);

  useEffect(() => {
    if (isIntersecting) {
      fetchMore({
        variables: {
          after: data?.products?.page?.pageInfo?.endCursor,
        },
      });
    }
  }, [isIntersecting, data?.products?.page?.pageInfo?.endCursor, fetchMore]);

  const handleJumpToTop = (e) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    fetchMore({
      variables: {
        after: data?.products?.page?.pageInfo?.endCursor,
      },
    });
  };

  return (
    <>
      <Modal
        modalIsOpen={state.isSearchFilterModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => toggleSearchFilterModal()}
        dismissModal={() => dismissSearchFilterModal()}
        content={<SearchFilter />}
        hideDismissBtn={true}
      />
      {/* The Grid */}
      <div
        className="mx-auto min-h-screen"
        style={{
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateRows: "5em 16em auto auto",
        }}
      >
        {/* Header */}
        {/* Header Hack */}
        <div className="header"></div>
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={(e) => handleJumpToTop(e)}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
              <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
                <CategorySharp
                  className={
                    filterKeys?.length > 0
                      ? "text-green-400 hover:cursor-pointer bg-transparent border-0"
                      : "hover:cursor-pointer bg-transparent border-0"
                  }
                  onClick={() => toggleSearchFilterModal()}
                />
              </div>
              <input
                className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba caret-black"
                placeholder={"Que cherchez vous ?"}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
                onChange={(e) => handleSearchBar(e)}
                type="text"
                value={state.searchingFor}
                name="searchingFor"
                ref={inputRef}
                style={{
                  fontFamily: "Leckerli One",
                }}
              />
            </div>
          </div>
        </div>
        {/* Slider */}
        <Slider />

        {/* Content */}
        {loading && called && products?.length === 0 ? (
          <div className="flex justify-center items-center">
            <Spinner
              style={{
                position: "absolute",
              }}
              className="inline-flex h-16 max-w-max bottom-1/3"
            />
          </div>
        ) : (
          <>
            <Content products={products} />
            {hasNextPage && products?.length >= 50 && (
              <div
                ref={ref}
                className="flex justify-center items-center w-full"
              >
                <Button
                  title="Plus"
                  disabled={isRefetching}
                  className="max-w-min"
                  isWaiting={isRefetching}
                  handleOnClick={(e) => handleLoadMore(e)}
                />
              </div>
            )}
          </>
        )}

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

export default Home;
