import { useEffect, useRef } from "react";
import { RootState } from "../../app/store";
import logo from "./../../assets/logo/logo.png";
import { FaSms } from "react-icons/fa";
import { ReactComponent as EmptyWishlist } from "./../../assets/img/take-away-animate.svg";
import { ReactComponent as CheckingBoxes } from "./../../assets/img/checking-boxes-animate.svg";
import { ReactComponent as InNoTime } from "./../../assets/img/in-no-time-animate.svg";
import { HashLink as Link } from "react-router-hash-link";
import Banner from "../../components/layout/banner";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  dismissNewDeliveryModal,
  dismissWrapUpModal,
  toggleNewDeliveryModal,
  toggleWrapUpModal,
} from "../../features/deliveries/deliveriesSlice";
import Modal from "../../components/common/modal";
import NewDelivery from "../../components/ux/new-delivery";
import { TbArrowBadgeRight } from "react-icons/tb";
import DeliveryWrapUp from "../../components/common/new-delivery-wrap-up";
import { CallSharp, StoreSharp, WhatsApp } from "@mui/icons-material";
import Footer from "../../components/layout/footer";
import { PRODUCTS } from "../../api/product/queries";
import { useQuery } from "@apollo/client";
import { setProducts } from "../../features/shops/shopsSlice";

function Landing() {
  const dispatch = useAppDispatch();
  const topRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const delivery = useAppSelector(
    (state: RootState) => state?.deliveries?.delivery
  );

  let first = 500;

  const { data, loading, called, fetchMore, networkStatus } = useQuery(
    PRODUCTS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      errorPolicy: "all",
      variables: {
        first,
      },
    }
  );

    useEffect(() => {
      dispatch(setProducts(data?.products?.page?.edges));
    }, [data, dispatch]);

  const handleJumpToTop = (e) => {
    topRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "start",
    });
  };

  return (
    <>
      <Modal
        content={<NewDelivery isRadioHiden={true} />}
        className="w-11/12 md:w-3/5"
        modalIsOpen={delivery?.isNewDeliveryModalOpen}
        handleModal={() => dispatch(toggleNewDeliveryModal())}
        dismissModal={() => dispatch(dismissNewDeliveryModal())}
      />
      <Modal
        content={<DeliveryWrapUp />}
        className="w-11/12 md:w-3/5"
        modalIsOpen={delivery?.isWrapUpModalOpened}
        handleModal={() => dispatch(toggleWrapUpModal())}
        dismissModal={() => dispatch(dismissWrapUpModal())}
      />
      <div
        className="mx-auto max-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "5em auto",
        }}
      >
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={(e) => handleJumpToTop(e)}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <Link to="/marketplace" className="group">
              <div className="min-w-max border border-djeliba absolute mx-1 flex right-36 bottom-7 invisible group-hover:visible rounded-md px-1 bg-white ">
                <span className="text-xs font-thin">Voir les produits</span>
              </div>
              <div className="flex rounded group hover:cursor-pointer p-2 justify-between items-center min-w-max m-2 px-2 border border-djeliba shadow-2xl hover:shadow-inner transition-all ease-linear duration-300">
                <span>MarketPlace</span>
                <span>
                  <StoreSharp />
                </span>
              </div>
            </Link>
          </div>
        </div>
        {/* Header */}
        <div className="header"></div>
        {/* Content*/}
        <div className="col-span-full overflow-y-scroll h-screen pt-5">
          <h1
            ref={topRef}
            className="flex font-bold text-4xl md:text-7xl text-center my-7 justify-center items-center"
          >
            La plateforme de distribution destinée exclusivement aux
            vendeurs(es).
          </h1>
          <div
            className="flex flex-col justify-center items-center p-3 m-2"
            id="services"
          >
            <Link to="#services" smooth>
              <h2 className="text-4xl md:text-5xl text-djeliba">
                Nos services
              </h2>
            </Link>
            <div className="flex flex-col md:flex-row justify-center items-center p-3 m-2">
              <Link to="#services/distribution" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">Distribution</span>
                </div>
              </Link>
              <Link to="#services/inventory-management" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">Gestion de stock</span>
                </div>
              </Link>
              <Link to="#services/e-commerce" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">E-Commerce</span>
                </div>
              </Link>
            </div>
            <div className="flex flex-col w-full md:flex-row p-2 m-1 md:p-3 md:m-2">
              <div
                id="services/distribution"
                className="flex flex-col md:w-1/2 w-full"
              >
                <div className="flex w-full md:border-b border-djeliba justify-start md:justify-center items-center">
                  <span className="text-2xl">Distribution</span>
                </div>
                {/* Web */}
                <div className="md:flex justify-start items-center w-full p-2 m-2 flex-col hidden h-full">
                  <p className="flex m-3">
                    Grâce à une technologie de pointe et à des pratiques
                    méticuleuses, nous donnons la priorité à l'efficacité et à
                    la précision dans tous les aspects de notre processus de
                    distribution.
                  </p>
                  <p className="flex m-3">
                    Notre service est taillé expressément pour répondre aux
                    besoins des vendeurs(es) en ligne pour qui la distribution
                    reste un enjeu décisif pour la satisfaction des clients.
                  </p>
                  <p className="flex m-3">
                    Une couverture sans faille, permettant une livraison rapide
                    aux clients dans Dakar et sa banlieue ainsi que dans
                    diverses régions.
                  </p>
                </div>
              </div>
              <div className="flex md:w-1/2 w-full md:border-l md:border-t border-djeliba rounded">
                <EmptyWishlist />
              </div>
              <div className="flex justify-start items-center w-full p-2 m-2 flex-col md:hidden h-full">
                <p className="flex m-3">
                  Grâce à une technologie de pointe et à des pratiques
                  méticuleuses, nous donnons la priorité à l'efficacité et à la
                  précision dans tous les aspects de notre processus de
                  distribution.
                </p>
                <p className="flex m-3">
                  Notre service est taillé expressément pour répondre aux
                  besoins des vendeurs(es) en ligne pour qui la distribution
                  reste un enjeu décisif pour la satisfaction des clients.
                </p>
                <p className="flex m-3">
                  Une couverture sans faille, permettant une livraison rapide
                  aux clients dans Dakar et sa banlieue ainsi que dans diverses
                  régions.
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full md:flex-row p-2 m-1 md:p-3 md:m-2">
              <div className="flex w-full md:border-b border-djeliba justify-start md:justify-center items-center md:hidden">
                <span className="text-2xl">Gestion de stock</span>
              </div>
              <div className="flex md:w-1/2 w-full md:border-r md:border-t border-djeliba rounded">
                <CheckingBoxes />
              </div>
              <div
                id="services/inventory-management"
                className="flex flex-col md:w-1/2 w-full"
              >
                {/* Web */}
                <div className="md:flex w-full md:border-b border-djeliba justify-start md:justify-center items-center hidden">
                  <span className="text-2xl">Gestion de stock</span>
                </div>
                <div className="flex justify-start items-center w-full p-2 m-2 flex-col h-full">
                  <p className="flex m-3">
                    Grâce à nos entrepôts stratégiquement situés dans des
                    régions clés, nous garantissons une livraison rapide et
                    rentable à vos clients, où qu'ils se trouvent.
                  </p>
                  <p className="flex m-3">
                    Nous nous occupons du traitement des commandes, la mise à
                    jour des stocks et le suivi des expéditions, ce qui vous
                    permet de vous concentrer sur le développement de nouveau
                    produit, de votre marketing, de la vente etc...
                  </p>
                  <p className="flex m-3">
                    Ce service est tout simplement un autre levier que nous
                    utilisons pour accélérer la distribution des marchandises,
                    avant que votre client(e) ne change d'avis.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full md:flex-row p-2 m-1 md:p-3 md:m-2">
              <div
                id="services/e-commerce"
                className="flex flex-col md:w-1/2 w-full"
              >
                <div className="flex w-full md:border-b border-djeliba justify-start md:justify-center items-center">
                  <span className="text-2xl">E-Commerce</span>
                </div>
                <div className="md:flex justify-start items-center w-full p-2 m-2 flex-col hidden h-full">
                  <p className="flex m-3">
                    Pour les partenaires qui le souhaitent nous avons une
                    fonctionnalité qui vous permet de rendre accessible au grand
                    public votre catalogue de produits entreposés dans nos
                    locaux.
                  </p>
                  <p className="flex m-3">
                    Ainsi, vos clients pourront accéder encore plus facilement
                    et rapidement à vos produits lors de future commande.
                  </p>
                  <p className="flex m-3">
                    Ceci vous permet à long terme de réduire considérablement
                    votre dépendance et vos dépenses publicitaire sur Facebook
                    ou d'autres réseaux sociaux pour pouvoir vendre.
                  </p>
                </div>
              </div>
              <div className="flex md:w-1/2 w-full md:border-l md:border-t border-djeliba rounded">
                <InNoTime />
              </div>
              <div className="flex justify-start items-center w-full p-2 m-2 flex-col md:hidden h-full">
                <p className="flex m-3">
                  Pour les partenaires qui le souhaitent nous avons une
                  fonctionnalité qui vous permet de rendre accessible au grand
                  public votre catalogue de produits entreposés dans nos locaux.
                </p>
                <p className="flex m-3">
                  Ainsi, vos clients pourront accéder encore plus facilement et
                  rapidement à vos produits lors de future commande.
                </p>
                <p className="flex m-3">
                  Ceci vous permet à long terme de réduire considérablement
                  votre dépendance et vos dépenses publicitaire sur Facebook ou
                  d'autres réseaux sociaux pour pouvoir vendre.
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col justify-center items-center p-3 m-2"
            id="how-to-contact-us"
            ref={contactRef}
          >
            <Link to="#how-to-contact-us" smooth>
              <h2 className={"text-4xl md:text-5xl text-djeliba"}>
                Nous contacter
              </h2>
            </Link>
            <div className="flex max-w-full flex-col md:flex-row justify-center items-center p-2 m-1 md:p-3 md:m-2">
              <div className="flex justify-evenly w-80 py-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://wa.me/221785271515?text=Salut%20Djéliba.`}
                >
                  <WhatsApp
                    className="border rounded-md p-1 text-green-500"
                    fontSize="large"
                  />
                </a>
                <a target="_blank" rel="noreferrer" href={`tel:+221785271515`}>
                  <CallSharp
                    className="border rounded-md p-1 text-blue-500"
                    fontSize="large"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`sms:+221785271515?&body=Salut Djéliba.`}
                >
                  <FaSms className="border rounded-md p-1 text-blue-500 text-4xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

export default Landing;
