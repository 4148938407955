import React, { useRef, useState } from "react";
import { RootState } from "../../app/store";
import icon from "../../assets/logo/icon.png";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/layout/banner";
import { addToCart } from "../../features/cart/cartSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import logo from "./../../assets/logo/logo.png";
import {
  CategorySharp,
  ClearSharp,
  ShoppingCartSharp,
} from "@mui/icons-material";
import {
  dismissValidationModal,
  removeFromWishlist,
  toggleValidationModal,
} from "../../features/wishlist/wishlistSlice";
import Modal from "../../components/common/modal";
import Validation from "../../components/ux/wishlist-validation";

import { searchFor } from "../../features/deliveries/deliveriesSlice";
import { ReactComponent as EmptyWishlist } from "./../../assets/img/empty-pana.svg";
interface Props {}

function WishList(props: Props) {
  const [state, setState] = useState({
    isSearchFilterModalOpen: false,
    searchingFor: "",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const filterKeys = useAppSelector(
    (state: RootState) => state.products.filterKeys
  );

  const isValidationModalOpen = useAppSelector(
    (state: RootState) => state.wishlist.isValidationModalOpen
  );

  const wishlistItems = useAppSelector(
    (state: RootState) => state.wishlist.wishlistItems
  );

  const handleRemoveFromWishlist = (e, productId) => {
    e.preventDefault();
    dispatch(removeFromWishlist(productId));
  };

  const handleAddToCart = (e, product) => {
    e.preventDefault();
    dispatch(removeFromWishlist(product._id));
    dispatch(addToCart(product));
  };

  const handleGoToShop = (e, product) => {
    e.preventDefault();
    navigate("/shops/" + product?.shop?._id, {
      state: {
        ...product?.shop,
      },
    });
  };

  const handleGoToDetail = (e, product) => {
    navigate("/shops/products/" + product?._id, {
      state: {
        ...product,
      },
    });
  };

  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const toggleSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: !prevState.isSearchFilterModalOpen,
    }));
  };

  const handleSearchBar = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchingFor: e.target.value,
    }));

    dispatch(searchFor(e.target.value));
  };

  return (
    <>
      <Modal
        modalIsOpen={isValidationModalOpen}
        className="w-4/5 md:w-4/12"
        handleModal={() => dispatch(toggleValidationModal())}
        dismissModal={() => dispatch(dismissValidationModal())}
        content={<Validation />}
      />
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "5em auto",
        }}
      >
        {/* Header */}
        {/* Header Hack */}
        <div className="header"></div>
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={() => {
                navigate("/marketplace");
              }}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
              <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
                <CategorySharp
                  className={
                    filterKeys?.length > 0
                      ? "text-green-400 hover:cursor-pointer bg-transparent border-0"
                      : "hover:cursor-pointer bg-transparent border-0"
                  }
                  onClick={() => toggleSearchFilterModal()}
                />
              </div>
              <input
                className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba caret-black"
                placeholder={"Que cherchez vous ?"}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
                onChange={(e) => handleSearchBar(e)}
                type="text"
                value={state.searchingFor}
                name="searchingFor"
                ref={inputRef}
                style={{
                  fontFamily: "Leckerli One",
                }}
              />
            </div>
          </div>
        </div>

        {/* Content */}
        {wishlistItems?.length > 0 ? (
          <div
            className=""
            style={{
              gridColumn: "1 / -1",
            }}
          >
            <div className="flex flex-col my-5 md:flex-row justify-between md:justify-evenly w-full h-full px-2 md:p-7">
              <div className="flex flex-col w-full md:w-1/2 md:mx-7">
                {wishlistItems?.map((product, i) => (
                  <div
                    className="flex w-full mb-3 h-32 border rounded-md hover:shadow-2xl transition-all ease-linear duration-300 p-2"
                    key={i}
                  >
                    {/* Product Image */}
                    <div className="w-1/3 md:w-1/4 flex-shrink-0">
                      <img
                        onClick={(e) => handleGoToDetail(e, product)}
                        src={product?.uri}
                        alt="Product"
                        className="object-cover w-full h-full rounded-md"
                      />
                    </div>

                    {/* Product Details & Actions */}
                    <div className="flex w-2/3 md:w-3/4 px-2 justify-between items-center">
                      {/* Product Info */}
                      <div className="flex flex-col justify-between h-full w-4/5">
                        <div>
                          <span
                            onClick={(e) => handleGoToDetail(e, product)}
                            className="text-ellipsis overflow-hidden font-bold whitespace-nowrap block"
                          >
                            {product?.name}
                          </span>
                          <span
                            onClick={(e) => handleGoToShop(e, product)}
                            className="text-yellow-500 text-sm font-bold hover:cursor-pointer block whitespace-nowrap overflow-hidden text-ellipsis max-w-min hover:underline"
                          >
                            {product?.shop?.name}
                          </span>
                        </div>
                        <span className="font-bold p-1">
                          {product?.price} F CFA
                        </span>
                      </div>

                      {/* Actions (Remove & Add to Cart) */}
                      <div className="flex flex-col justify-between h-full w-1/5">
                        <button
                          className="flex group items-center justify-end md:bg-djeliba rounded-md md:px-2 p-1 hover:cursor-pointer"
                          onClick={(e) =>
                            handleRemoveFromWishlist(e, product?._id)
                          }
                        >
                          <ClearSharp className="group-hover:text-red-500" />
                          <span className="hidden md:flex">Retirer</span>
                        </button>
                        <button
                          className="flex group items-center justify-end md:bg-djeliba rounded-md md:px-2 p-1 hover:cursor-pointer"
                          onClick={(e) => handleAddToCart(e, product)}
                        >
                          <ShoppingCartSharp className="group-hover:text-green-500" />
                          <span className="hidden md:flex">Ajouter</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col items-center w-full md:w-1/4 h-40">
                <div className="flex flex-wrap items-center justify-around w-full h-full bg-black rounded-md p-2 mb-4">
                  <div className="flex max-w-max px-7 py-2 bg-green-400 rounded-md hover:cursor-pointer">
                    <span
                      onClick={() => dispatch(toggleValidationModal())}
                      className="font-bold text-white"
                    >
                      Finalisez ma wishlist
                    </span>
                  </div>
                  {/* <hr className="flex w-1/2 border border-white" />
                  <div className="flex max-w-max px-7 py-2 bg-green-400 rounded-md hover:cursor-pointer">
                    <span className="font-bold text-white">
                      Finalisez une wishlist
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center md:h-1/3 flex-col">
            <EmptyWishlist />
          </div>
        )}
        <div
          onClick={() => {
            navigate("/marketplace");
          }}
          className="hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none"
        >
          <img src={icon} alt="" className="object-scale-down rounded-full" />
        </div>
      </div>
    </>
  );
}

export default WishList;
