import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface ShopsState {
  isWaiting: boolean;
  error: any;
  orders: object[];
  products: any[];
  transactions: object[];
  shop: {
    _id: string;
    logo: string;
    name: string;
    segments: string[];
    isNameValid: boolean;
    isNewShopModalOpen: boolean;
  };
}

const initialState: ShopsState = {
  isWaiting: false,
  error: null,
  orders: [],
  products: [],
  transactions: [],
  shop: {
    _id: "",
    logo: "",
    name: "",
    segments: [],
    isNameValid: false,
    isNewShopModalOpen: false,
  },
};

export const shopSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    stopWaiting: (state) => {
      state.isWaiting = false;
    },
    setShop: (state, action: PayloadAction<any>) => {
      state.shop = {
        ...state.shop,
        ...action.payload,
      };
    },
    setOrders: (state, action: PayloadAction<object[]>) => {
      state.orders = action.payload;
    },
    setTransactions: (state, action: PayloadAction<object[]>) => {
      state.transactions = action.payload;
    },
    setProducts: (state, action: PayloadAction<object[]>) => {
      state.products = action.payload;
    },
    removeFromProducts: (state, action: PayloadAction<string>) => {
      state.products = state.products.filter(
        (item) => item?.node._id !== action.payload
      );
    },
    updateProduct: (state, action: PayloadAction<any>) => {
      state.products = state.products?.map((item) =>
        item?.node?._id === action.payload?._id
          ? { ...item, ...action.payload }
          : item
      );
    },
    setShopName: (state, action: PayloadAction<string>) => {
      state.shop.name = action.payload;
      state.shop.isNameValid = !/[!@#$%^&*()+\-=[\]{};:"\\|,.<>/?]+/.test(
        action.payload
      );
    },
    setLogo: (state, action: PayloadAction<string>) => {
      state.shop.logo = action.payload;
    },
    setSegments: (state, action: PayloadAction<string>) => {
      state.shop.segments = state.shop.segments.includes(action.payload)
        ? state.shop.segments.filter((item) => item !== action.payload)
        : [...state.shop.segments, action.payload];
    },
    toggleNewShopModal: (state) => {
      state.shop.isNewShopModalOpen = !state.shop.isNewShopModalOpen;
    },
    dismissNewShopModal: (state) => {
      state.shop.isNewShopModalOpen = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isWaiting = false;
    },
    clearStorage() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setLogo,
  hasError,
  setShop,
  setOrders,
  setProducts,
  stopWaiting,
  startWaiting,
  clearStorage,
  setSegments,
  setShopName,
  updateProduct,
  setTransactions,
  removeFromProducts,
  toggleNewShopModal,
  dismissNewShopModal,
} = shopSlice.actions;

export default shopSlice.reducer;

export const uploadShopLogo = (id, data) => async (dispatch) => {
  try {
    await axios
      .put(`https://lol.djeliba.com/shops/${id}/logo`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        dispatch(stopWaiting());
      })
      .catch((err) => {
        console.error(err);
        dispatch(stopWaiting());
      });
  } catch (error) {
    return console.error(error);
  }
};
