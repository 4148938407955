import "./App.css";
import Home from "./screens/home";
import Landing from "./screens/landing";
import ErrorPage from "./screens/error-page";
import { createBrowserRouter } from "react-router-dom";
import Cart from "./screens/cart";
// import Profile from "./screens/profile";
import Orders from "./screens/orders";
import WishList from "./screens/wishlist";
import Desktop from "./screens/desktop";
import Dashbord from "./screens/desktop/dashbord";
import Products from "./screens/desktop/products";
import Campaigns from "./screens/desktop/campaigns";
import Settings from "./screens/desktop/settings";
import Transactions from "./screens/desktop/transactions";
import Shops from "./screens/shops/shops";
import Shop from "./screens/shops/shop";
import BecomeSeller from "./screens/shops/become-seller";
import Product from "./screens/shops/product";
import Deliveries from "./screens/desktop/deliveries";

const App = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/shops",
    children: [
      {
        path: "",
        element: <Shops />,
      },
      {
        path: ":shopId",
        element: <Shop />,
      },
      {
        path: "become-seller",
        element: <BecomeSeller />,
      },
      {
        path: "products/:productId",
        element: <Product />,
      },
    ],
  },
  {
    path: "/marketplace",
    element: <Home />,
  },
  {
    path: "/marketplace/cart",
    element: <Cart />,
  },
  {
    path: "/marketplace/orders",
    element: <Orders />,
  },
  {
    path: "/marketplace/wishlist",
    element: <WishList />,
  },
  // {
  //   path: "/profile",
  //   element: <Profile />,
  // },
  /**
   * Interface d'aministration des vendeurs
   */
  {
    path: "/desktop",
    element: <Desktop />,
    children: [
      {
        path: ":shopId",
        element: <Dashbord />,
      },
      {
        path: ":shopId/products",
        element: <Products />,
      },
      {
        path: ":shopId/campaigns",
        element: <Campaigns />,
      },
      {
        path: ":shopId/deliveries",
        element: <Deliveries />,
      },
      {
        path: ":shopId/settings",
        element: <Settings />,
      },
      {
        path: ":shopId/transactions",
        element: <Transactions />,
      },
    ],
  },
]);

export default App;
