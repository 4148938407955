import { gql } from "@apollo/client";

export const PRODUCTS = gql`
  query products($first: Float!, $after: String) {
    products(first: $first, after: $after) {
      page {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            _id
            price
            name
            description
            isDeliveryFree
            shop {
              _id
              name
              logo
            }
            images {
              xs
              sm
              md
              lg
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_BY_SHOP = gql`
  query productsByShop($shopId: String!) {
    productsByShop(first: 50, shopId: $shopId) {
      page {
        pageInfo {
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            _id
            price
            name
            status
            quantity
            description
            isDeliveryFree
            categories {
              _id
              name
            }
            shop {
              _id
              name
              logo
            }
            images {
              xs
              sm
              md
              lg
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_BY_SHOP_ID = gql`
  query productsByShopId($shopId: String!) {
    productsByShopId(first: 50, shopId: $shopId) {
      page {
        pageInfo {
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            _id
            price
            name
            status
            quantity
            description
            isDeliveryFree
            categories {
              _id
              name
            }
            shop {
              _id
              name
              logo
            }
            images {
              xs
              sm
              md
              lg
            }
          }
        }
      }
    }
  }
`;

export const SHOP_PRODUCT_RECOMMENDATION = gql`
  query productsByShop($shopId: String!) {
    productsByShop(first: 5, shopId: $shopId) {
      page {
        pageInfo {
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            _id
            price
            name
            status
            quantity
            description
            isDeliveryFree
            categories {
              _id
              name
            }
            shop {
              _id
              name
              logo
            }
            images {
              xs
              sm
              md
              lg
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT = gql`
  query Product($productId: ID!) {
    product(id: $productId) {
      _id
      price
      description
      isDeliveryFree
      quantity
      images {
        lg
      }
      shop {
        _id
        name
        logo
      }
    }
  }
`;
